import './gcuContent.scss';

const GCUContent = (): JSX.Element => {
  return (
    <div className="gcu-content-root">
      <div className="gcu-content-wrapper">
        <p className="text-14-normal version">
          CONDITIONS GENERALES D'UTILISATION (CGU)
        </p>
        <p className="text-14-normal version">Version 1.0 du 23/01/2025</p>

        <ol>
          <li className="gcu-content-part-title text-15-normal">
            <strong>Objet</strong>
            <p className="content-text">
              Les présentes Conditions Générales d'Utilisation (CGU) ont pour
              objet de définir les modalités et conditions dans lesquelles les
              utilisateurs peuvent accéder et utiliser l'application web
              "Eco-Gagnant".
            </p>
          </li>

          <li className="gcu-content-part-title text-15-normal">
            <strong>Acceptation des CGU</strong>
            <p className="content-text">
              L'utilisation de l'application "Eco-Gagnant" implique
              l'acceptation pleine et entière des présentes CGU. En créant un
              compte, l'utilisateur reconnaît avoir lu, compris et accepté les
              CGU.
            </p>
          </li>

          <li className="gcu-content-part-title text-15-normal">
            <strong>Accès à l'application</strong>
            <p className="content-text">
              L'accès à l'application "Eco-Gagnant" est réservé aux utilisateurs
              disposant d'un compte. La création d'un compte nécessite la
              fourniture d'informations personnelles telles que le nom, le
              prénom et l'adresse e-mail.
            </p>
          </li>

          <li className="gcu-content-part-title text-15-normal">
            <strong>Services proposés</strong>
            <p className="text-content">
              L'application "Eco-Gagnant" permet aux utilisateurs de :
            </p>
            <ul>
              <li>
                Suivre leurs consommations d'électricité, de gaz et d'eau à
                différents pas de temps (semaine, mois, année, sur les cinq
                dernières années).
              </li>
              <li>
                Comparer leurs consommations avec des périodes précédentes.
              </li>
              <li>
                Recevoir des astuces personnalisées pour réduire leurs
                consommations.
              </li>
              <li>
                Participer à des défis pour encourager la réduction des
                consommations.
              </li>
              <li>
                Analyser leurs consommations et les comparer avec des profils
                similaires.
              </li>
              <li>
                Accéder à l'information sur les dispositifs du programme
                Eco-Gagnant.
              </li>
              <li>
                Créer des alertes et recevoir des notifications en cas de
                dépassement de consommations.
              </li>
            </ul>
            <p className="text-content">
              <strong>Note importante :</strong> L'application "Eco-Gagnant" est
              actuellement compatible uniquement avec les compteurs d'eau gérés
              par L’Eau du Dunkerquois. Les utilisateurs situés en dehors de
              cette zone ne pourront pas suivre leurs consommations d'eau via
              l'application.
            </p>
          </li>
          <li className="gcu-content-part-title text-15-normal">
            <strong>Données personnelles</strong>
            <p className="text-content">
              Les données personnelles collectées sont utilisées pour fournir
              les services de l'application. Les utilisateurs peuvent à tout
              moment révoquer leur consentement depuis les sites partenaires ou
              en envoyant un mail. Les données sont supprimées immédiatement à
              la fin de la relation contractuelle ou de la dernière interaction
              avec l'utilisateur.
            </p>
            <p className="text-content">
              <strong>Consentements</strong>
            </p>
            <p className="text-content">
              Les consentements donnés aux gestionnaires de réseaux (ENEDIS,
              GRDF, L’Eau du Dunkerquois) concernent exclusivement les données
              de consommation (électricité, gaz, eau). Ces consentements ne sont
              pas conservés sur la plateforme Eco-Gagnant.
            </p>
            <p className="text-content">
              <strong>Suppression des données</strong>
            </p>
            <p className="text-content">
              Lorsque l'utilisateur supprime son compte Eco-Gagnant, toutes les
              données relatives à ce compte sont supprimées. Si l'utilisateur
              recrée un compte, il est préférable qu'il mette fin au
              consentement donné aux gestionnaires de réseaux pour la
              transmission de ses données de consommation avant de recréer un
              nouveau compte.
            </p>
          </li>
          <li className="gcu-content-part-title text-15-normal">
            <strong>Sécurité et confidentialité</strong>
            <p className="text-content">
              La Communauté Urbaine de Dunkerque s'engage à assurer la sécurité
              et la confidentialité des données collectées. Des mesures
              techniques et organisationnelles appropriées sont mises en place
              pour protéger les données contre tout accès non autorisé, perte ou
              divulgation. Les données ne seront pas partagées avec des tiers
              sans le consentement explicite de l'utilisateur.
            </p>
          </li>
          <li className="gcu-content-part-title text-15-normal">
            <strong>Cookies et outils de suivi</strong>
            <p className="text-content">
              L'application utilise Matomo pour analyser l'utilisation de
              l'application. Matomo est configuré pour ne pas collecter de
              données personnelles. Les données collectées incluent les pages
              visitées, la durée de consultation et le type de device utilisé.
              Les utilisateurs peuvent refuser les cookies installés par Matomo
              sans que cela n'affecte leur accès à l'application.
            </p>
          </li>
          <li className="gcu-content-part-title text-15-normal">
            <strong>Responsabilités</strong>
            <p className="text-content">
              L'utilisateur s'engage à utiliser l'application de manière
              conforme à la loi et aux présentes CGU. La Communauté Urbaine de
              Dunkerque ne pourra être tenue responsable des dommages directs et
              indirects causés au matériel de l'utilisateur lors de l'accès à
              l'application.
            </p>
          </li>
          <li className="gcu-content-part-title text-15-normal">
            <strong>Limitation de responsabilité</strong>
            <p className="text-content">
              La Communauté Urbaine de Dunkerque s'efforce de fournir des
              informations précises et à jour sur l'application "Eco-Gagnant".
              Cependant, des erreurs ou des omissions peuvent survenir. La CUD
              ne pourra être tenue responsable des conséquences de ces erreurs
              ou omissions, sauf en cas de faute lourde ou intentionnelle.
            </p>
            <p className="text-content">
              L'utilisateur reconnaît qu'il utilise les informations et services
              de l'application à ses propres risques. La CUD ne pourra être
              tenue responsable des dommages indirects, tels que la perte de
              données ou de revenus, qui pourraient résulter de l'utilisation ou
              de l'incapacité à utiliser l'application, malgré les mesures de
              sécurité mises en place.
            </p>
          </li>
          <li className="gcu-content-part-title text-15-normal">
            <strong>Modification des CGU</strong>
            <p className="text-content">
              La Communauté Urbaine de Dunkerque se réserve le droit de modifier
              les présentes CGU à tout moment. Les utilisateurs seront informés
              de toute modification par e-mail ou via une notification sur
              l'application.
            </p>
          </li>
          <li className="gcu-content-part-title text-15-normal">
            <strong>Loi applicable et juridiction compétente</strong>
            <p className="text-content">
              Les présentes CGU sont régies par la loi française. En cas de
              litige, les tribunaux français seront seuls compétents.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default GCUContent;

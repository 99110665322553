import logoEcoGagnantWhite from 'assets/png/logo-eco-gagnant-blanc.png';
import logos from 'assets/png/eco-gagnant-ensemble.png';
import { InitSteps } from 'models';
import './splashScreen.scss';
import { useTranslation } from 'react-i18next';

const SplashScreen = ({ initStep }: { initStep: InitSteps }) => {
  const { t } = useTranslation();
  const getProgress = () => {
    const total = Object.entries(InitSteps).length / 2;
    const step = initStep ?? 1;
    const progress = Math.round((step / total) * 100);
    return progress;
  };

  return (
    <>
      <div className="splash-content">
        <div className="splash-loader">
          <div className="logo-wrap">
            <img src={logoEcoGagnantWhite} alt="Chargement" />
          </div>
          <div className="splash-progress">
            <div className="splash-progress-bar-container">
              <div
                className="splash-progress-bar-content"
                style={{ width: `${getProgress()}%` }}
              />
            </div>
          </div>
        </div>
        <div className="step-label text-18-normal">
          {t(`splashscreen.step.${initStep}`)}
        </div>
        <div className="splash-logos-container">
          <img src={logos} alt="ensemble de logos" />
        </div>
      </div>
    </>
  );
};

export default SplashScreen;

import { Duration } from 'luxon';

// enums
import { FluidType } from 'enums';
// models
import {
  StellioAlert,
  StellioEvent,
  StellioFluidMonitoring,
  StellioSubscription,
} from 'models/stellio.model';
// utils
import { getStellioFluidSlug } from 'utils/utils';
// components
import { NotifDurationType } from 'components/NotificationsView/NewNotificationTab/NewNotificationTab';

export const getFluidMonitoringId = (
  userFluidMonitorings: StellioFluidMonitoring[],
  fluidType: FluidType
): string | undefined => {
  const slug = getStellioFluidSlug(fluidType);
  const userFluidMonitoring = userFluidMonitorings.find(
    (fluidMonitoring) => fluidMonitoring.fluidType.value === slug
  );

  if (!userFluidMonitoring) {
    console.warn('FluidMonitoring entity not found; cannot get ID');
    return;
  }

  return userFluidMonitoring.id;
};

export const getDurationType = (duration: Duration) => {
  if (duration.months > 0) {
    return 'months';
  } else if (duration.weeks > 0) {
    return 'weeks';
  } else if (duration.days > 0) {
    return 'days';
  } else {
    throw new Error('Invalid duration');
  }
};

export const getDurationValue = (
  duration: Duration,
  durationType: NotifDurationType
) => {
  const durationObject = duration.toObject();
  if (durationObject[durationType]) {
    return durationObject[durationType];
  } else {
    throw new Error('Invalid duration or duration type');
  }
};

interface EventParams {
  threshold: number;
  duration: Duration;
  alertId: string;
  eventId: string;
  fluidMonitoringId: string;
}

export const getStellioEvent = ({
  eventId,
  threshold,
  duration,
  alertId,
  fluidMonitoringId,
}: EventParams): StellioEvent => {
  const event: StellioEvent = {
    id: eventId,
    type: ['Event', 'AggregatedThresholdBreach'],
    hasSubject: {
      type: 'Relationship',
      watchedProperties: {
        type: 'Property',
        value: 'consumption',
      },
      object: fluidMonitoringId,
    },
    occurrence: {
      type: 'Property',
      value: 0,
    },
    status: {
      type: 'Property',
      value: false,
    },
    thresholdType: {
      type: 'Property',
      value: 'Upper',
    },
    thresholdValue: {
      type: 'Property',
      value: threshold,
    },
    thresholdAggrPeriod: {
      type: 'Property',
      value: duration,
    },
    hasAlert: {
      type: 'Relationship',
      object: alertId,
    },
  };

  return event;
};

interface AlertParams {
  eventId: string;
  alertId: string;
  email: string;
  descriptionRest: string;
}

export const getStellioAlert = ({
  eventId,
  alertId,
  email,
  descriptionRest,
}: AlertParams): StellioAlert => {
  const alert: StellioAlert = {
    id: alertId,
    type: 'Alert',
    hasSubject: {
      type: 'Relationship',
      object: eventId,
    },
    mailingList: {
      type: 'Property',
      value: {
        email: [email],
      },
    },
    message: {
      type: 'JsonProperty',
      json: {
        subject: '[ECO-GAGNANT] [ALERTE] Limite de consommation dépassée',
        body: `La limite de consommation que vous avez fixée à $\{consumption_limit} a été dépassée. Vous avez consommé \${consumption_sum}.`,
        limitDescription: descriptionRest,
      },
    },
    occurrence: {
      type: 'Property',
      value: false,
    },
  };

  return alert;
};

export const getFluidUnit = (fluidType: FluidType) =>
  fluidType === FluidType.WATER ? 'L' : 'kWh';

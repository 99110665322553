import './legalNoticeView.scss';

const LegalNoticeContent = () => {
  return (
    <div className="legal-notice-root">
      <div className="legal-notice-content">
        <p className="text-14-normal version">MENTIONS LEGALES</p>
        <p className="text-14-normal version">Version 1.0 du 23/01/2025</p>

        <ol>
          <li>
            <strong>Identification de l’éditeur du site</strong>
            <ul>
              <li>
                <strong>Site :</strong>{' '}
                <a href="https://www.eco-gagnant.cud.fr">
                  www.eco-gagnant.cud.fr
                </a>
              </li>
              <li>
                <strong>Collectivité :</strong> COMMUNAUTE URBAINE DE DUNKERQUE
              </li>
              <li>
                <strong>Adresse :</strong> Pertuis de la Marine, BP 5530, 59386
                DUNKERQUE CEDEX 1
              </li>
              <li>
                <strong>Téléphone :</strong> +33 (0)3 28 62 70 00
              </li>
              <li>
                <strong>Numéro SIRET :</strong> 245 900 428 00013
              </li>
            </ul>
          </li>
          <li>
            <strong>Directeur de la publication</strong>
            <ul>
              <li>
                <strong>Nom :</strong> Patrice VERGRIETE
              </li>
              <li>
                <strong>Fonction :</strong> Président
              </li>
            </ul>
          </li>
          <li>
            <strong>Hébergeur du site</strong>
            <ul>
              <li>
                <strong>Nom :</strong> OVH (OVHCLOUD)
              </li>
              <li>
                <strong>Adresse :</strong> ZI Les Huttes, Route de la Ferme
                Masson, 59820 Gravelines
              </li>
              <li>
                <strong>Téléphone :</strong> +33 (0)3 20 19 20 00
              </li>
            </ul>
          </li>
          <li>
            <strong>Prestataire de développement de l’application</strong>
            <ul>
              <li>
                <strong>Entreprise :</strong> EGM
              </li>
              <li>
                <strong>Adresse :</strong> AREP Center, 1 Traverse des Brucs,
                06560 Valbonne
              </li>
              <li>
                <strong>Téléphone :</strong> +33 (0)9 82 33 06 72
              </li>
              <li>
                <strong>Email :</strong>{' '}
                <a href="mailto:contact@egm.io">contact@egm.io</a>
              </li>
            </ul>
          </li>
          <li>
            <strong>Propriété intellectuelle</strong>
            <p>
              Tous les contenus présents sur ce site, incluant, de manière non
              limitative, les graphismes, images, textes, vidéos, animations,
              sons, logos, gifs et icônes ainsi que leur mise en forme sont la
              propriété exclusive de la communauté urbaine de Dunkerque à
              l’exception des marques, logos ou contenus appartenant à d’autres
              sociétés partenaires ou auteurs.
            </p>
            <p>
              Ce site utilise du code open source provenant de l’application
              Ecolyo développée par la Métropole de Lyon. La communauté urbaine
              de Dunkerque a récupéré ce code et l’a modifié pour l’application
              Eco-Gagnant. Le code original est disponible sous la licence GNU
              AGPL v3. La communauté urbaine de Dunkerque remercie la Métropole
              de Lyon pour la mise à disposition de ce code.
            </p>
            <p>
              En outre, la communauté urbaine de Dunkerque a créé un code commun
              avec la Métropole de Lyon pour améliorer et enrichir les
              fonctionnalités de l’application. Cette initiative s’inscrit dans
              une volonté de créer des communs numériques afin que l’application
              puisse être proposée par d’autres collectivités. Les communs
              numériques favorisent la collaboration, réduisent les coûts,
              encouragent l’innovation ouverte, améliorent l’interopérabilité,
              renforcent la souveraineté numérique et assurent la durabilité des
              projets.
            </p>
            <p>
              Le code sera bientôt disponible en open source sous la licence GNU
              AGPL v3, après un travail de documentation.
            </p>
            <p>
              Toute reproduction, distribution, modification, adaptation,
              retransmission ou publication, même partielle, de ces différents
              éléments est strictement interdite sans l’accord exprès par écrit
              de la communauté urbaine de Dunkerque.
            </p>
          </li>
          <li>
            <strong>Données personnelles</strong>
            <p>
              La communauté urbaine de Dunkerque collecte uniquement les données
              personnelles strictement nécessaires à l’application et au service
              rendu :
            </p>
            <ul>
              <li>
                Informations sur le logement : date de construction, surface,
                équipements, nombre d’occupants, etc.
              </li>
              <li>
                Données de consommation : consommation d’électricité, de gaz et
                d’eau provenant des compteurs communicants.
              </li>
              <li>Données d’identification : nom, prénom, adresse e-mail</li>
              <li>
                Communication mensuelle : Les utilisateurs peuvent recevoir une
                lettre mensuelle contenant un bilan et des conseils sur leur
                consommation. Ils peuvent choisir de ne pas recevoir cette
                communication en se désinscrivant directement depuis
                l'application.
              </li>
              <li>
                Service assistance utilisateur : Les utilisateurs peuvent
                envoyer un e-mail au service assistance utilisateur pour poser
                des questions, signaler des problèmes ou proposer des
                améliorations. L'adresse e-mail du service assistance
                utilisateur est : eco-gagnant@cud.fr.
              </li>
            </ul>

            <p>
              Les données collectées sont utilisées pour les finalités suivantes
              :
            </p>
            <ul>
              <li>
                Fournir des estimations de consommations d’énergie et de
                fluides.
              </li>
              <li>
                Offrir des conseils personnalisés avec des astuces pour réduire
                les consommations.
              </li>
              <li>
                Permettre la participation à des défis pour encourager la
                réduction des consommations.
              </li>
              <li>Améliorer les services proposés par l’application.</li>
              <li>
                Communication mensuelle : Envoyer une lettre mensuelle contenant
                un bilan et des conseils sur la consommation.
              </li>
            </ul>

            <p>
              Les partenaires du service ENEDIS, GRDF et L’Eau du Dunkerquois
              sont responsables exclusivement des seuls traitements de Données
              Personnelles relatifs à la collecte des données de consommation
              d’électricité, de gaz et d’eau de l’utilisateur et à leur
              transmission sur la plateforme du site Eco-Gagnant, après
              consentement de l’utilisateur.
            </p>

            <p>Durée de conservation des données :</p>
            <ul>
              <li>
                Les données sont supprimées immédiatement à compter de la fin de
                la relation contractuelle ou de la dernière interaction avec
                l’utilisateur, sauf obligation légale contraire.
              </li>
              <li>
                Les comptes inactifs depuis plus de 2 ans sont supprimés après
                deux rappels.
              </li>
              <li>
                Les données de correspondance avec le service assistance
                utilisateur sont conservées pendant la durée nécessaire au
                traitement de la demande et archivées pendant une période de 1
                an.
              </li>
            </ul>

            <p>
              Conformément au Règlement Général sur la Protection des Données
              (RGPD), vous disposez des droits suivants sur vos données
              personnelles :
            </p>
            <ul>
              <li>
                Droit d’accès : vous pouvez obtenir des informations sur les
                données personnelles que la communauté urbaine de Dunkerque
                détient à votre sujet.
              </li>
              <li>
                Droit de rectification : vous pouvez demander la correction de
                vos données personnelles si elles sont inexactes ou incomplètes.
              </li>
              <li>
                Droit à l’effacement : vous pouvez demander la suppression de
                vos données personnelles dans certaines conditions.
              </li>
              <li>
                Droit à la limitation du traitement : vous pouvez demander la
                limitation du traitement de vos données personnelles dans
                certaines conditions.
              </li>
              <li>
                Droit d’opposition : vous pouvez vous opposer au traitement de
                vos données personnelles pour des raisons tenant à votre
                situation particulière.
              </li>
              <li>
                Droit à la portabilité : vous pouvez demander à recevoir vos
                données personnelles dans un format structuré, couramment
                utilisé et lisible par machine.
              </li>
              <li>
                Droit de retrait : vous pouvez choisir de ne pas recevoir la
                lettre mensuelle en vous désinscrivant via le lien fourni dans
                chaque communication ou en contactant le service assistance
                utilisateur.
              </li>
            </ul>
            <p>
              Afin de faciliter l’exercice de vos droits, la Communauté Urbaine
              de Dunkerque a désigné un Délégué à la Protection des Données que
              vous pouvez joindre en utilisant les moyens suivants :
            </p>
            <ul>
              <li>
                par écrit en adressant un courrier signé à l'adresse suivante :
                Communauté Urbaine de Dunkerque - à l'attention du Délégué à la
                Protection des Données - Pertuis de la Marine - BP 85530 - 59386
                DUNKERQUE CEDEX 1
              </li>
              <li>
                par formulaire disponible sur le site internet de la Communauté
                Urbaine de Dunkerque :{' '}
                <a href="https://www.communaute-urbaine-dunkerque.fr/?id=1691">
                  https://www.communaute-urbaine-dunkerque.fr/?id=1691
                </a>
              </li>
              <li>
                par courriel à l'adresse : eco-gagnant@cud.fr et/ou
                protection.donnees@cud.fr
              </li>
            </ul>

            <p>
              La Communauté Urbaine de Dunkerque s’engage à répondre à toute
              demande d’exercice de vos droits dans les délais légaux en
              vigueur.
            </p>
            <p>
              Vous disposez également d’un droit de réclamation auprès de la
              Commission Nationale Informatique et Libertés (www.cnil.fr).
            </p>
            <p>
              Conformément à la réglementation en vigueur en matière de
              protection des données personnelles, le service Eco-Gagnant a fait
              l’objet d’une inscription au registre des traitements de la
              communauté urbaine de Dunkerque.
            </p>

            <p>Sécurité et confidentialité :</p>
            <ul>
              <li>
                La communauté urbaine de Dunkerque s’engage à assurer la
                sécurité et la confidentialité des données collectées. Les
                données ne seront pas partagées avec des tiers sans le
                consentement explicite de l’utilisateur. Veuillez noter que
                l’adresse exacte des utilisateurs n’est pas collectée. La
                collecte des données de consommation se fait uniquement après
                que l’utilisateur a donné son consentement au gestionnaire de
                réseau.
              </li>
            </ul>
          </li>

          <li>
            <strong>Cookies et outils de suivi</strong>
            <p>
              La communauté urbaine de Dunkerque utilise Matomo pour analyser
              l’utilisation de l’application. Matomo est configuré pour ne pas
              collecter de données personnelles.
            </p>
            <p>
              Les données collectées incluent les pages visitées, la durée de
              consultation et le type d’appareil utilisé. Les utilisateurs
              peuvent refuser les cookies installés par Matomo sans que cela
              n'affecte leur accès à l'application. Seuls les cookies
              strictement nécessaires au bon fonctionnement de l’application
              seront conservés.
            </p>
          </li>
          <li>
            <strong>Liens hypertextes</strong>
            <p>
              L’application Eco-Gagnant peut contenir des liens hypertextes vers
              d’autres sites web. La Communauté Urbaine de Dunkerque n’exerce
              aucun contrôle sur ces sites et décline toute responsabilité quant
              à leur contenu. L’utilisateur est invité à consulter les mentions
              légales et les politiques de confidentialité de ces sites.
            </p>
          </li>
          <li>
            <strong>Conditions d’utilisation</strong>
            <p>
              L’utilisation de l’application Eco-Gagnant est soumise à
              l’acceptation des conditions générales d’utilisation. En utilisant
              l’application, vous acceptez ces conditions.
            </p>
            <p>
              L’utilisateur s’engage à utiliser l’application de manière
              conforme à la loi et aux présentes conditions générales
              d’utilisation. La Communauté Urbaine de Dunkerque ne pourra être
              tenue responsable des dommages directs et indirects causés au
              matériel de l’utilisateur, lors de l’accès à l’application
              Eco-Gagnant, et résultant soit de l’utilisation d’un matériel ne
              répondant pas aux spécifications indiquées, soit de l’apparition
              d’un bug ou d’une incompatibilité.
            </p>
          </li>
          <li>
            <strong>Loi applicable et juridiction compétente</strong>
            <p>Les mentions légales sont régies par la loi française.</p>
            <p>
              En cas de litige, seuls les tribunaux français sont compétents.
            </p>
          </li>
          <li>
            <strong>Référentiel Général de Sécurité (RGS)</strong>
            <p>
              L'application web est actuellement en cours d'homologation
              conformément aux exigences du Référentiel Général de Sécurité
              (RGS). Cette homologation attestera des moyens mis en œuvre pour
              que le système soit en cohérence avec la cible de sécurité
              définie.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default LegalNoticeContent;
